import '@flowplayer/player/flowplayer.css';

import React, { useEffect, useRef, useState } from 'react';
import Flowplayer, { useFlowplayer } from '@flowplayer/react-flowplayer';
import flowplayer from '@flowplayer/player';
import HLSPlugin from '@flowplayer/player/plugins/hls';
import KeyboardPlugin from '@flowplayer/player/plugins/keyboard';
import ThumbnailsPlugin from '@flowplayer/player/plugins/thumbnails';
import QualityPlugin from '@flowplayer/player/plugins/qsel';
import SpeedPlugin from '@flowplayer/player/plugins/speed';
import ChromecastPlugin from '@flowplayer/player/plugins/chromecast';
import AirplayPlugin from '@flowplayer/player/plugins/airplay';

import { ENDED, PAUSE, PLAYING } from '@flowplayer/player/core/events';

// Register plugins
flowplayer(HLSPlugin);
flowplayer(KeyboardPlugin);
flowplayer(ThumbnailsPlugin);
flowplayer(QualityPlugin);
flowplayer(SpeedPlugin);
flowplayer(ChromecastPlugin);
flowplayer(AirplayPlugin);

type VideoPlayerProps = {
  thumbnail?: string;
};

const VideoPlayer = ({ thumbnail }: VideoPlayerProps) => {
  const playerRef = useRef<HTMLDivElement | null>(null);
  const playerApi = useFlowplayer(playerRef);

  const [demoPlaybackState, setDemoPlaybackState] = useState('paused');
  const [config, setConfig] = useState<any | null>(null);
  const PLAYER_ID = '0386ad2c-b6e3-4dc4-9c28-88db6e6f9055';
  const demoSrc =
    'https://wv-cdn-00-00.wowza.com/1be155dd-e4ce-473d-8810-4ad391156072/cmaf/b91573cd-b9d4-4ca3-9e81-f445e2a4e9bb/playlist.m3u8';
  const DEMO_TOKEN =
    'eyJraWQiOiI3dVFjM2pGbU9lNVAiLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6MzgsXCJpZFwiOlwiN3VRYzNqRm1PZTVQXCJ9IiwiaXNzIjoiRmxvd3BsYXllciJ9.WihkBshkBCmCm1CpDY36kK3VHJbGLhIg8hTbHD4-ZcCITCpP61-D1pGOtUNDAH1-zC4XIpiyixHYAUycwbJIGw';

  const onHandleState = (ev: Event) => {
    if (ev.type === PAUSE) setDemoPlaybackState('paused');
    if (ev.type === PLAYING) setDemoPlaybackState('playing');
    if (ev.type === ENDED) setDemoPlaybackState('ended');
  };

  useEffect(() => {
    (async () => {
      // Fetch player config
      const config = await fetch(
        'https://ljsp.lwcdn.com/web/public/native/config/' + PLAYER_ID
      );
      const playerConfig = await config.text();
      setConfig(playerConfig);
    })();
  }, []);

  // Listen to player events for the demo
  useEffect(() => {
    if (!playerApi) return;
    playerApi.on([PAUSE, PLAYING, ENDED], onHandleState);
    playerApi.togglePlay();
    return () => {
      // Cleanup on unmount
      if (!playerApi) return;
      playerApi.off(PAUSE, onHandleState);
      playerApi.off(PLAYING, onHandleState);
      playerApi.off(ENDED, onHandleState);
    };
  }, [playerApi]);

  return (
    <>
      {demoPlaybackState === 'ended' ? (
        <div>Player has ended</div>
      ) : (
        <Flowplayer
          src={demoSrc}
          token={DEMO_TOKEN}
          ref={playerRef}
          opts={{ autoplay: true, poster: thumbnail, ...config, ratio: '16:9' }}
        />
      )}
    </>
  );
};

export default VideoPlayer;
